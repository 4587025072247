var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page-layout',{staticClass:"recomm"},[_c('display-container',{staticClass:"box_basic box_roll",attrs:{"section":34,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{staticClass:"container ctnr_dark"},[_c('p',{staticClass:"img_wrap"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('div',{staticClass:"con_tit"},[_vm._v(_vm._s(component.getTitle(row))+" "),_c('p',{staticClass:"s_txt1"},[_vm._v(_vm._s(component.getText1(row)))]),_c('p',{staticClass:"s_txt2"},[_vm._v(_vm._s(component.getText2(row)))])])])}}])}),_c('div',{staticClass:"box_basic box_recomm"},[_c('p',{staticClass:"tit_l"},[_vm._v("올해 휴가는 제주도 어때 🏝")]),_c('div',{staticClass:"scroller"},[_c('div',{staticClass:"inner"},[_c('display-container',{attrs:{"section":36,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{staticClass:"container ctnr_l ctnr_dark"},[_c('p',{staticClass:"img_wrap"},[_c('img',{staticClass:"fitimg",attrs:{"src":component.getImage(row)}})]),_c('div',{staticClass:"con_tit"},[_c('p',{staticClass:"cate"},[_vm._v(_vm._s(component.getCate(row)))]),_c('p',{staticClass:"title"},[_vm._v(_vm._s(component.getTitle(row)))])])])}}])})],1)]),_c('div',{staticClass:"scroller"},[_c('div',{staticClass:"inner inner_s num-3"},[_c('display-container',{attrs:{"section":37,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{staticClass:"container ctnr_s"},[_c('p',{staticClass:"img_wrap"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"name_kr"},[_vm._v(_vm._s(component.getName(row)))]),_c('p',{staticClass:"name_en"},[_vm._v(_vm._s(component.getNameEng(row)))])])}}])}),_c('display-container',{attrs:{"section":38,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{staticClass:"container ctnr_s"},[_c('p',{staticClass:"img_wrap"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"name_kr"},[_vm._v(_vm._s(component.getName(row)))]),_c('p',{staticClass:"name_en"},[_vm._v(_vm._s(component.getNameEng(row)))])])}}])}),_c('display-container',{attrs:{"section":39,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{staticClass:"container ctnr_s"},[_c('p',{staticClass:"img_wrap"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"name_kr"},[_vm._v(_vm._s(component.getName(row)))]),_c('p',{staticClass:"name_en"},[_vm._v(_vm._s(component.getNameEng(row)))])])}}])})],1)])]),_c('div',{staticClass:"box_basic box_recomm section-2"},[_c('p',{staticClass:"tit_l"},[_vm._v("인피니티푸울에 푸웅덩! 🤿")]),_c('div',{staticClass:"scroller"},[_c('div',{staticClass:"inner left"},[_c('display-container',{attrs:{"section":41,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{staticClass:"container ctnr_l ctnr_dark"},[_c('p',{staticClass:"img_wrap"},[_c('img',{staticClass:"fitimg",attrs:{"src":component.getImage(row)}})]),_c('div',{staticClass:"con_tit"},[_c('p',{staticClass:"cate"},[_vm._v(_vm._s(component.getCate(row)))]),_c('p',{staticClass:"title"},[_vm._v(_vm._s(component.getTitle(row)))])])])}}])}),_c('display-container',{attrs:{"section":42,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{staticClass:"container ctnr_l ctnr_dark"},[_c('p',{staticClass:"img_wrap"},[_c('img',{staticClass:"fitimg",attrs:{"src":component.getImage(row)}})]),_c('div',{staticClass:"con_tit"},[_c('p',{staticClass:"cate"},[_vm._v(_vm._s(component.getCate(row)))]),_c('p',{staticClass:"title"},[_vm._v(_vm._s(component.getTitle(row)))])])])}}])})],1),_c('div',{staticClass:"inner right"},[_c('display-container',{attrs:{"section":43,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{staticClass:"container ctnr_l ctnr_dark"},[_c('p',{staticClass:"img_wrap"},[_c('img',{staticClass:"fitimg",attrs:{"src":component.getImage(row)}})]),_c('div',{staticClass:"con_tit"},[_c('p',{staticClass:"cate"},[_vm._v(_vm._s(component.getCate(row)))]),_c('p',{staticClass:"title"},[_vm._v(_vm._s(component.getTitle(row)))])])])}}])})],1)]),_c('router-link',{staticClass:"btn_more drtn_right",attrs:{"to":"/search/hotel?hash_tags=%EC%9D%B8%ED%94%BC%EB%8B%88%ED%8B%B0%ED%92%80\n"}},[_vm._v("인피니티풀 호텔 더 보기")])],1),_c('div',{staticClass:"box_basic"},[_c('p',{staticClass:"tit_l"},[_vm._v("어서와! 무착륙은 처음이라구? 🛫")]),_c('div',{staticClass:"scroller"},[_c('div',{staticClass:"inner inner_m num-3"},[_c('display-container',{attrs:{"section":45,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{staticClass:"container ctnr_m ctnr_dark"},[_c('p',{staticClass:"img_wrap"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"con_tit"},[_vm._v(_vm._s(component.getTitle(row)))])])}}])}),_c('display-container',{attrs:{"section":46,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{staticClass:"container ctnr_m ctnr_dark"},[_c('p',{staticClass:"img_wrap"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"con_tit"},[_vm._v(_vm._s(component.getTitle(row)))])])}}])}),_c('display-container',{attrs:{"section":47,"align":"left"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return _c('div',{staticClass:"container ctnr_m ctnr_dark"},[_c('p',{staticClass:"img_wrap"},[_c('img',{attrs:{"src":component.getImage(row)}})]),_c('p',{staticClass:"con_tit"},[_vm._v(_vm._s(component.getTitle(row)))])])}}])})],1)])]),_c('div',{staticClass:"box_basic"},[_c('p',{staticClass:"tit_l"},[_vm._v("초호화 객실 랜선 숙박 💸")]),_c('display-container',{attrs:{"section":49,"align":"left","no-auto-link":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return (row.model_data)?_c('div',{staticClass:"video_player"},[_c('iframe',{attrs:{"src":_vm.getVideo(row.model_data.url),"title":component.getTitle(row),"frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})]):_vm._e()}}],null,true)}),_c('display-container',{attrs:{"section":50,"align":"left","no-auto-link":true},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var component = ref.component;
var row = ref.row;
return (row.model_data)?_c('div',{staticClass:"video_player"},[_c('iframe',{attrs:{"src":_vm.getVideo(row.model_data.url),"title":component.getTitle(row),"frameborder":"0","allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})]):_vm._e()}}],null,true)}),_c('router-link',{staticClass:"btn_more drtn_right btn_video",attrs:{"to":"/video"}},[_vm._v("VIDEO 보러가기")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }