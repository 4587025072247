<template>
  <page-layout class="recomm">

		<!-- 롤링콘텐츠 -->
		<!--<div class="box_basic box_roll">-->
			<!--<router-link to="/">-->
				<!--<div class="container ctnr_dark">-->
					<!--<p class="img_wrap">-->
						<!--<img src="https://cdn.prestigegorilla.net/old/site/2021/1/517f6640-5ac5-11eb-b94e-81594ab0d0a7.jpg">-->
					<!--</p>-->
					<!--<p class="con_tit">HOTEL HOT 100<br>4월 2주차 RANKING 📊</p>-->
					<!--<ul class="pager">-->
						<!--<li class="on"></li>-->
						<!--<li></li>-->
						<!--<li></li>-->
						<!--<li></li>-->
					<!--</ul>-->
				<!--</div>-->
			<!--</router-link>-->
		<!--</div>-->
		<display-container :section="34" align="left" class="box_basic box_roll">
			<div slot-scope="{ component, row }" class="container ctnr_dark">
				<p class="img_wrap">
					<img :src="component.getImage(row)">
				</p>
				<div class="con_tit">{{component.getTitle(row)}}
          <p class="s_txt1">{{component.getText1(row)}}</p>
          <p class="s_txt2">{{component.getText2(row)}}</p>
				</div>
			</div>
		</display-container>
		<!-- //롤링콘텐츠 -->

  	<!-- 섹션1 -->
    <div class="box_basic box_recomm">
    	<p class="tit_l">올해 휴가는 제주도 어때 🏝</p>
    	<div class="scroller">
    		<div class="inner">
    			<display-container :section="36" align="left">
    				<div slot-scope="{ component, row }" class="container ctnr_l ctnr_dark">
    					<p class="img_wrap">
    						<img :src="component.getImage(row)" class="fitimg">
    					</p>
    					<div class="con_tit">
    						<p class="cate">{{component.getCate(row)}}</p>
    						<p class="title">{{component.getTitle(row)}}</p>
    					</div>
    				</div>
    			</display-container>
    		</div>
    	</div>
    	<div class="scroller">
				<div class="inner inner_s num-3">
					<display-container :section="37" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_s">
							<p class="img_wrap">
								<img :src="component.getImage(row)">
							</p>
							<p class="name_kr">{{component.getName(row)}}</p>
							<p class="name_en">{{component.getNameEng(row)}}</p>
						</div>
					</display-container>
					<display-container :section="38" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_s">
							<p class="img_wrap">
								<img :src="component.getImage(row)">
							</p>
							<p class="name_kr">{{component.getName(row)}}</p>
							<p class="name_en">{{component.getNameEng(row)}}</p>
						</div>
					</display-container>
					<display-container :section="39" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_s">
							<p class="img_wrap">
								<img :src="component.getImage(row)">
							</p>
							<p class="name_kr">{{component.getName(row)}}</p>
							<p class="name_en">{{component.getNameEng(row)}}</p>
						</div>
					</display-container>
				</div>
			</div>
			<!--<router-link to="/" class="btn_more drtn_right">제주도 호텔 더 보기</router-link>-->
    </div>
    <!--// 섹션1 -->

    <!-- 섹션2 -->
    <div class="box_basic box_recomm section-2">
    	<p class="tit_l">인피니티푸울에 푸웅덩! 🤿</p>
    	<div class="scroller">
				<div class="inner left">
					<display-container :section="41" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_l ctnr_dark">
							<p class="img_wrap">
								<img :src="component.getImage(row)" class="fitimg">
							</p>
							<div class="con_tit">
								<p class="cate">{{component.getCate(row)}}</p>
								<p class="title">{{component.getTitle(row)}}</p>
							</div>
						</div>
					</display-container>
					<display-container :section="42" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_l ctnr_dark">
							<p class="img_wrap">
								<img :src="component.getImage(row)" class="fitimg">
							</p>
							<div class="con_tit">
								<p class="cate">{{component.getCate(row)}}</p>
								<p class="title">{{component.getTitle(row)}}</p>
							</div>
						</div>
					</display-container>
				</div>
				<div class="inner right">
					<display-container :section="43" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_l ctnr_dark">
							<p class="img_wrap">
								<img :src="component.getImage(row)" class="fitimg">
							</p>
							<div class="con_tit">
								<p class="cate">{{component.getCate(row)}}</p>
								<p class="title">{{component.getTitle(row)}}</p>
							</div>
						</div>
					</display-container>
				</div>
			</div>
			<router-link to="/search/hotel?hash_tags=%EC%9D%B8%ED%94%BC%EB%8B%88%ED%8B%B0%ED%92%80
" class="btn_more drtn_right">인피니티풀 호텔 더 보기</i></router-link>
    </div>
    <!--// 섹션2 -->

    <!-- 섹션3 -->
    <div class="box_basic">
    	<p class="tit_l">어서와! 무착륙은 처음이라구? 🛫</p>
    	<div class="scroller">
    		<div class="inner inner_m num-3">
					<display-container :section="45" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_m ctnr_dark">
							<p class="img_wrap">
								<img :src="component.getImage(row)">
							</p>
							<p class="con_tit">{{component.getTitle(row)}}</p>
						</div>
					</display-container>
					<display-container :section="46" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_m ctnr_dark">
							<p class="img_wrap">
								<img :src="component.getImage(row)">
							</p>
							<p class="con_tit">{{component.getTitle(row)}}</p>
						</div>
					</display-container>
					<display-container :section="47" align="left">
						<div slot-scope="{ component, row }" class="container ctnr_m ctnr_dark">
							<p class="img_wrap">
								<img :src="component.getImage(row)">
							</p>
							<p class="con_tit">{{component.getTitle(row)}}</p>
						</div>
					</display-container>
    		</div>
    	</div>
    </div>
    <!--// 섹션3 -->

    <!-- 섹션4 -->
    <div class="box_basic">
    	<p class="tit_l">초호화 객실 랜선 숙박 💸</p>
			<display-container :section="49" align="left" :no-auto-link="true">
				<div slot-scope="{ component, row }" class="video_player" v-if="row.model_data">
					<iframe :src="getVideo(row.model_data.url)" :title="component.getTitle(row)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				</div>
			</display-container>
			<display-container :section="50" align="left" :no-auto-link="true">
				<div slot-scope="{ component, row }" class="video_player" v-if="row.model_data">
					<iframe :src="getVideo(row.model_data.url)" :title="component.getTitle(row)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
				</div>
			</display-container>
			<router-link to="/video" class="btn_more drtn_right btn_video">VIDEO 보러가기</router-link>
    </div>
    <!--// 섹션4 -->

  </page-layout>
</template>

<script>
  import PageLayout from '../layouts/PageLayout'
  import SubPageLayout from '../layouts/SubPageLayout'
  import DisplayContainer from '../components/DisplayContainer'
  import CommonComputed from 'src/_mixins/CommonComputed'
  import CommonMethods from 'src/_mixins/CommonMethods'
  export default {
    name: 'Special',
    components: {
      PageLayout,
      SubPageLayout,
      DisplayContainer
    },
		data () {
      return {
      }
    },
    mounted () {

    },
    computed: {
      ...CommonComputed,
      getAccess () {
        return 'app'
      }
    },
    methods: {
      ...CommonMethods
    }
  }
</script>

<style scoped>
	.recomm .box_roll .container.ctnr_dark .con_tit {
    font-size: 18px;
		text-align: center;
		right: 32px;
		padding-right: 0;
  }
  .recomm .box_roll .container.ctnr_dark .con_tit .s_txt1 {
    font-size: 14px;
    font-weight: 400;
  }
  .recomm .box_roll .container.ctnr_dark .con_tit .s_txt2 {
    font-size: 12px;
    font-weight: 400;
  }
	.box_recomm .scroller {
		margin-bottom: unset;
		padding-right: 32.5px;
	}
	.box_recomm .inner .con_tit .title {
		font-size: 15px;
		font-weight: 500;
		line-height: 1.5em;
		letter-spacing: -0.04em !important;
		overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
	}
	.box_recomm .inner .con_tit .cate {
		font-size: 12px;
		font-weight: 400;
		letter-spacing: 0;
		line-height: 12px;
		margin-bottom: 14px;
    border-bottom: 1px solid rgba(255,255,255,0.5);
    display: inline-block;
	}
	.box_recomm .inner .con_tit .cate i {
		margin-right: 12px;
		padding-right: 12px;
		border-right: 1px solid #fff;
		line-height: 12px;
		display: inline-block;
	}
	.box_recomm.section-2 .inner {
		display: inline-block;
		position: relative;
	}
	.box_recomm.section-2 .inner.left {
		width: calc(50% - 5px);
		margin-right: 5px;
	}
	.box_recomm.section-2 .inner.right {
		width: 50%;
	}
	.box_recomm.section-2 .inner .container {
		width: 100%;
		display: block;
	}
	.box_recomm.section-2 .inner.left .container .img_wrap {
		margin-bottom: 5px;
		height: 40vw;
	}
	.box_recomm.section-2 .inner.right .container .img_wrap {
		height: calc(80vw + 5px);
	}
	.box_recomm.section-2 .con_tit .title {
		font-size: 13px;
		font-weight: 500;
	}
	.box_recomm.section-2 .inner.left .con_tit .title {
		word-break: break-all;
	}
	.box_recomm.section-2 .inner.right .con_tit .title {
		-webkit-line-clamp: 4;
	}
	.box_recomm.section-2 .inner .display-container {
		display: block;
		width: 100%;
	}
	.box_basic .inner .con_tit {
		font-size: 14px;
	}
	.recomm .video_player {
		position: relative;
		height: 0;
		padding-bottom: calc((100vw - 65px) * 0.5625);
		margin-bottom: 10px;
	}
	.recomm .video_player iframe {
		position: absolute;
		width: 100%;
		height: 100%;
	}
	.btn_video {
		margin-top: 30px;
	}
</style>
