<script>
	import Special from 'src/pc/pages/Special'
  import PageLayout from '../layouts/PageLayout'
  import SubPageLayout from '../layouts/SubPageLayout'
  import DisplayContainer from '../components/DisplayContainer'
  export default {
    extends: Special,
    components: {
      PageLayout,
      SubPageLayout,
      DisplayContainer
    }
  }
</script>

<style scoped>
  .recomm .box_roll .container.ctnr_dark .con_tit {
    font-size: 18px;
    text-align: center;
    right: 32px;
    padding-right: 0;
  }
  .recomm .box_roll .container.ctnr_dark .con_tit .s_txt1 {
    font-size: 14px;
    font-weight: 400;
  }
  .recomm .box_roll .container.ctnr_dark .con_tit .s_txt2 {
    font-size: 12px;
    font-weight: 400;
  }
  .box_recomm .scroller {
    margin-bottom: unset;
    padding-right: 32.5px;
  }
  .box_recomm .inner .con_tit .title {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.5em;
    letter-spacing: -0.04em !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .box_recomm .inner .con_tit .cate {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 12px;
    margin-bottom: 14px;
    border-bottom: 1px solid rgba(255,255,255,0.5);
    display: inline-block;
  }
  .box_recomm .inner .con_tit .cate i {
    margin-right: 12px;
    padding-right: 12px;
    border-right: 1px solid #fff;
    line-height: 12px;
    display: inline-block;
  }
  .box_recomm.section-2 .inner {
    display: inline-block;
    position: relative;
  }
  .box_recomm.section-2 .inner.left {
    width: calc(50% - 5px);
    margin-right: 5px;
  }
  .box_recomm.section-2 .inner.right {
    width: 50%;
  }
  .box_recomm.section-2 .inner .container {
    width: 100%;
    display: block;
  }
  .box_recomm.section-2 .inner.left .container .img_wrap {
    margin-bottom: 5px;
    height: 40vw;
  }
  .box_recomm.section-2 .inner.right .container .img_wrap {
    height: calc(80vw + 5px);
  }
  .box_recomm.section-2 .con_tit .title {
    font-size: 13px;
    font-weight: 500;
  }
  .box_recomm.section-2 .inner.left .con_tit .title {
    word-break: break-all;
  }
  .box_recomm.section-2 .inner.right .con_tit .title {
    -webkit-line-clamp: 4;
  }
  .box_recomm.section-2 .inner .display-container {
    display: block;
    width: 100%;
  }
  .box_basic .inner .con_tit {
    font-size: 14px;
  }
  .recomm .video_player {
    position: relative;
    height: 0;
    padding-bottom: calc((100vw - 65px) * 0.5625);
    margin-bottom: 10px;
  }
  .recomm .video_player iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .btn_video {
    margin-top: 30px;
  }
</style>